import Logo from './shared/graphics/Logo.png'
import './App.css'

function App() {
  return (
    <div className="App">
      <div className="header">
      </div>
      <div className="body">
        <img src={Logo} alt="logo" />
      </div>
      <div className="footer">
        <div className="what">
          <div>Hosting</div>
          <div>Development</div>
          <div>Systems design</div>
          <div>Tezos Blockchain</div>
        </div>
        <div className="contact">
          <div><a href="mailto:hello@surflabs.net">hello@surflabs.net</a></div>
        </div>
        <div className="address">
          <div>Surf Labs AS</div>
          <div>Frøyerhagen 8</div>
          <div>4328 Sandnes</div>
          <div>Norway</div>
        </div>
      </div>
    </div>
  )
}

export default App
